@import url(//db.onlinewebfonts.com/c/ea82369846239b74436ec7ba3eff1f78?family=Madera+W01);

.App {
  width: 70%;
  text-align: center;
  margin: auto;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .App {
    width: auto;
  }
}
.redirect {
  margin-top: 35%;
}

@media screen and (max-width: 425px) {
  .redirect {
    margin-top: 70%;
  }
}
